import { useRef } from 'react';
import { Image } from '@mantine/core';
import styles from './news-card.module.scss';
import { ArrowRight } from '@/shared/assets/svg';
import { CardProps } from './img-cards';

export function NewsCard({ image, title, date, tag, alternativeText, mediaFormat, onlyImg }: CardProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const previewPicture = mediaFormat?.includes('video') ? (
    <video
      ref={videoRef}
      src={image}
      autoPlay
      loop
      muted
      playsInline
      className={styles.img}
    ></video>) : (
    <Image className={styles.img} src={image} alt={alternativeText} />);

  return (
    <div className={styles.wrapper}>
      <div className={styles.imgWrapper}>
        {previewPicture}
      </div>
      { !onlyImg && (
        <>
          <div className={styles.description}>
            <span className={styles.text}>{title}</span>
            <span className={styles.date}>{date}</span>
            <div className={styles.more}>
              <span>Подробнее</span>
              <ArrowRight className={styles.more__arrow} />
            </div>
          </div>
          {tag && <div className={styles.tag}>{tag}</div>}
        </>
      )}
    </div>
  );
}
